class headerReportModal extends Component {

    static name() {
        return "headerReportModal";
    }

    static componentName() {
        return "headerReportModal";
    }

    data() {
        return {
            reportName:"",
        };
    }

    getMethods() {
        return {
            openReport:this.openReport
        };
    }

    openReport(){
        if(this.reportName)
            this.$router.push('/report/'+this.reportName);
    }

    getTemplate() {
        return `<div id="reportModal" ref="reportModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="reportModallLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content" v-if="$store.state.session">
                        <div class="modal-header">
                            <h5 class="modal-title" id="reportModallLabel">{{tr('Select a Report')}}</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-6" >
                                    <template v-for="(report, index) in $store.getters.getReports">
                                        <template v-if="index % 2==0">
                                           <p :key="reportName=report.fields.ReportName">
                                              <input name="group1" type="radio" :id="reportName=report.fields.ReportName" @change="reportName=report.fields.ReportName" />
                                              <label :for="reportName=report.fields.ReportName">{{tr(report.fields.ReportTitle)}}</label>
                                           </p>
                                       </template>
                                    </template>
                                </div>
                                <div class="col-6" >
                                    <template v-for="(report , index) in  $store.getters.getReports">
                                        <template v-if="index%2!=0">
                                            <p :key="reportName=report.fields.ReportName">
                                              <input name="group1" type="radio" :id="reportName=report.fields.ReportName" @change="reportName=report.fields.ReportName" />
                                              <label :for="reportName=report.fields.ReportName">{{tr(report.fields.ReportTitle)}}</label>
                                           </p>
                                        </template>
                                    </template>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <a href="#" data-dismiss="modal" class="btn btn-secondary" @click.prevent="openReport" >{{tr("Open Report")}}</a>  
                            <a href="#" data-dismiss="modal" class="btn btn-secondary" >{{tr("Close")}}</a>
                        </div>
                    </div>
                </div>
            </div> `;
    }
}

headerReportModal.registerComponent();